import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./index.css"
import { Link } from "gatsby"
import Contact from "../components/contact"
import { Location} from "../components/location"
import Card from "../components/card"
import brush from "../images/icons/brush.svg"
import paintingRoll from '../images/icons/painting-roll.svg'
import tape from '../images/icons/tape.svg'

const IndexPage = () => (
  <Layout>
    <Seo title="Strona Główna" />
      <div className="container py-16 about">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="py-12 lg:py-20">
            <h1 className="font-bold text-4xl text-secondary mb-4" style={{ marginLeft: '-5px'}}>Witamy</h1>
            <div className="border-b-8 border-secondary w-12 mb-12"></div>
            <h2 className="font-bold text-2xl text-primary max-w-sm mb-12 leading-7">Producent wysokiej jakości pędzli malarskich</h2>
            <p className="text-lg mb-4 max-w-md text-body">
              Firma Wojmal istnieje nieprzerwanie od 1991 roku. Zajmujemy się profesjonalnie 
              produkcją pędzli malarskich oraz szczotek. Do produkcji używamy wysokogatunkowych 
              surowców i komponentów. Rączki, trzonki i oprawki wykonane są z drewna
            </p>
            <p className="text-lg max-w-md text-body">
              Ponadto prowadzimy sprzedaż innych akcesoriów malarskich i narzędzi budowlanych 
              typu: wałki malarskie, folie, taśmy, szpachelki, pace, mieszadła, rękawice, kratki 
              wentylacyjne, wiadra, kasty, kątowniki.
            </p>
          </div>
          <div className="hidden lg:block">
          </div>
        </div>    
      </div>
    <div className="bg-background">
      <div className="container bg-background py-24">
        <h1 className="font-bold text-4xl text-primary mb-4" style={{ marginLeft: '-5px'}}>Oferta</h1>
        <div className="border-b-8 border-primary w-12 mb-12"></div>
        <div className="grid md:grid-cols-3 gap-12 sm:grid-cols-1">
          <Card 
            icon={brush}
            header="Pędzle i szczotki"
            body="M.in angielskie, profesjonalne, kaloryferowe, tapetowe, ławkowce"
          />
          <Card 
            icon={paintingRoll}
            header="Wałki malarskie"
            body="Różnego rodzaju wałki malarskie"
          />
          <Card 
            icon={tape}
            header="Akcesoria malarskie"
            body="M.in folie, taśmy, szpachelki, pace, mieszadła, rękawice, wiadra"
          />
        </div>
        <div className="flex justify-center pt-12">
          <Link to="/oferta" className="py-4 px-8 border-4 rounded-full border-primary hover:bg-primary 
            text-primary font-bold hover:text-white transition duration-150">Zobacz pełną ofertę</Link>
        </div>
      </div>
    </div>
    <div className="bg-primary">
      <div className="container py-20 flex flex-col items-center">
        <h1 className="text-4xl font-bold text-white text-center mb-6">Zapraszamy do kontaktu</h1>
        <div className="border-b-8 border-secondary w-12 mb-6"></div>
        <p className="text-lg text-white max-w-lg text-center text-blue-50">Nasze produkty cechuje <strong>wysoka 
          jakość</strong> i <strong>niska cena</strong>, proponujemy korzystne warunki współpracy</p>
      </div>
    </div>
    <Contact />
    <Location />
  </Layout>
)

export default IndexPage
