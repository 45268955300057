import * as React from "react"

const Card = ({ header, body, icon }) => (
  <div className="bg-white rounded-lg p-8 flex flex-col">
    <div className="h-40 flex justify-center align-center">
        <img src={icon} alt={header} />
    </div>
    <h3 className="text-xl text-center font-bold text-body mb-3">{header}</h3>
    <p className="text-md text-center text-body">{body}</p>
  </div>
)

export default Card
